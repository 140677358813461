'use client';
import { Box, Card, styled } from '@mui/material';
import Image from 'next/image';

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '22rem',
  height: 'auto',
  maxHeight: '25rem',
  padding: 5,
  boxShadow: '3px 3px 4px #E8E8EA',
  border: '1px solid #E8E8EA',
  margin: '0 1rem',
  [theme.breakpoints.down(1200)]: {
    width: '20rem',
  },
  [theme.breakpoints.down(800)]: {
    width: '22rem',
  },
  [theme.breakpoints.down(400)]: {
    margin: '0 0',
  },
}));

export const StyledImage = styled(Image)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  borderRadius: '0.5rem',
}));

export const StyledTopicBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#e6e0f8',
  height: '2rem',
  maxWidth: '12rem',
  minWidth: '9rem',
  width: 'max-content',
  padding: 1,
  borderRadius: '0.3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'transform 200ms ease-in-out',
  },
}));

export const StyledDescBox = styled(Box)(() => ({
  width: '100%',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  paddingLeft: 5,
}));
