'use client';
import { Box, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FlexBox } from 'components/flex-box';
import Image from 'next/image';

export const StyledHeroBox = styled(FlexBox)(({ theme }) => ({
  height: '35rem',
  padding: 4,
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.breakpoints.values.md,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '100%',
    height: '18rem',
  },
  marginTop: 3,
}));

export const StyledImageBelowCard = styled(Image)(({ theme }) => ({
  width: '100%',
  height: '90%',
  objectFit: 'cover',
  aspectRatio: '16/9',
  borderRadius: '0.3rem',
}));

export const StyledCardAboveImage = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
  maxWidth: '25rem',
  height: '12rem',
  paddingLeft: 3,
  position: 'absolute',
  bottom: 10,
  left: 30,
  right: 20,
  gap: '0.5rem',
  padding: '1.1rem 1rem',
  boxShadow: '1px 3px 5px  #AAA',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '13rem',
    height: '9rem',
    bottom: 2,
    padding: '0.8rem 0.7rem',
  },
}));

export const StyledButton = styled('button')(({ theme }) => ({
  width: '8rem',
  height: '2.5rem',
  backgroundColor: 'transparent',
  color: '#696A75',
  borderRadius: '5px',
  border: '1px solid #aaa',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#ccc',
    color: 'black',
  },
}));

export const StyledDescBox = styled(Box)(() => ({
  width: '100%',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
}));

export const StyledTopicBadge = styled('div')(({ theme }) => ({
  fontWeight: '600',
  fontSize: '0.875rem',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  padding: '0.5rem 1rem',
  margin: '0.5rem',
  borderRadius: '0.5rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    textDecoration: 'none',
  },
}));

export const StaticHomePageSection = styled(FlexBox)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
  background: 'linear-gradient(90deg, #FFF -38.77%, #D9D4FF 100%)',
  height: '15rem',
  maxWidth: '100%',
  padding: 4,
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginRight: 0,
    height: '18rem',
  },
}));
